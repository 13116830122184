import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.dataTree), ([heading, subs], mainIndex) => {
      return (_openBlock(), _createElementBlock("div", { key: heading }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.stylesActiveTab(mainIndex), "font-bold p-2"])
        }, _toDisplayString(heading), 3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subs, (subheading, subIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: subheading,
            onClick: ($event: any) => (_ctx.$emit('goto-section', [mainIndex, subIndex]))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass([_ctx.stylesActiveSubTab(mainIndex, subIndex), "p-2 pl-8"])
            }, _toDisplayString(subheading), 3)
          ], 8, _hoisted_1))
        }), 128))
      ]))
    }), 128))
  ]))
}