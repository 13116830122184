
import { useAuth0 } from "@/services/auth0-plugin";

import HollowButtonVue from "@/components/particles/HollowButton.vue";
import VerticalTabsVue from "@/components/molecules/VerticalTabs.vue";
import FormCardVue from "@/components/atoms/FormCard.vue";

import { defineComponent, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { gql } from "graphql-tag";

export default defineComponent({
  components: {
    HollowButtonVue,
    VerticalTabsVue,
    FormCardVue,
  },
  setup() {
    const auth0 = useAuth0();

    const toc = {
      content: ["your forms"],
      settings: ["account & profile", "security"],
      // 'Balance & Billing': [],
    };
    const currSection = ref([Number(0), Number(0)]);

    const { result } = useQuery(gql`
      query getUser {
        users {
          name
          email
          branches {
            code
          }
        }
      }
    `);

    return {
      auth0,
      toc,
      currSection,
      result,
    };
  },
});
