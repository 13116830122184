
// data-tree
// {
//     'Heading': ['list', 'of', 'subheadings'],
//     'Next': [],
// }

import { defineComponent, toRefs } from "vue";

export default defineComponent({
  props: {
    dataTree: {
      type: Object,
      required: true,
    },
    currSection: {
      type: Array,
      required: true,
    },
  },
  emits: ["goto-section"],
  setup(props) {
    const { currSection } = toRefs(props);

    const stylesActiveSubTab = (mainIndex: number, subIndex: number) => {
      if (
        currSection.value[0] === mainIndex &&
        currSection.value[1] === subIndex
      )
        return "bg-blue-200 text-primary font-bold";
      return "bg-white text-black";
    };
    const stylesActiveTab = (mainIndex: number) => {
      if (currSection.value[0] === mainIndex)
        return "bg-blue-200 border-l-4 border-blue-500";
      return "bg-white";
    };

    return {
      stylesActiveSubTab,
      stylesActiveTab,
    };
  },
});
